<template>
  <div id="app">
    <abstraktion-navigation :modules="modules" :module="module" />
    <head-bar />
    <router-view />
  </div>
</template>

<script>
import HeadBar from "./components/HeadBar.vue";
import AbstraktionNavigation from "./components/AbstraktionNavigation.vue";

export default {
  name: "App",
  components: {
    HeadBar,
    AbstraktionNavigation,
  },
  beforeMount() {
    if (
      typeof document.getElementById("app").dataset !== "undefined" &&
      typeof document.getElementById("app").dataset.modules !== "undefined"
    )
      this.modules = document.getElementById("app").dataset.modules.split(",");
  },
  data() {
    return {
      modules: [],
      module: 'studio'
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
