const mutations = {
  USER_SET(state, data) {
    state.user = data;
  },
  PUSH_PRODUCT(state, payload) {
    //console.log(payload);
    state.products.push(payload);
  },
  IS_CLIENT(state, boolean) {
    state.is_client = boolean;
  },
  SET_STRIPE_LINK(state, data) {
    state.stripe_link = data;
  },
  USER_UNSET: (state) => {
    state.user = null;
  },
};

export default mutations;
