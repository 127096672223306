// https://levelup.gitconnected.com/firebase-auth-management-in-vue-js-with-vuex-9c4a5d9cedc

const getters = {
  getUser(state) {
    return state.user;
  },
  getUid(state) {
    return state.user.uid;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  getError(state) {
    return state.error;
  },
  getOptions(state) {
    return state.options;
  },
  //Stripe products
  getProducts(state) {
    return state.products;
  },
  isClient(state) {
    return state.is_client;
  },
  getClient: (state) => {
    return state.client;
  },
};

export default getters;
