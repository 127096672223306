// https://levelup.gitconnected.com/firebase-auth-management-in-vue-js-with-vuex-9c4a5d9cedc

import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
import * as fs from "../firestore";

Vue.use(Vuex);

const initialState = () => {
  return {
    user: null,
    subscription: null,
    subscription_role: null,
    subscription_status: null,
    is_client: false,
    client: null,
    error: null,
    // activate drive
    options: ["drive"],
    // Stripe
    products: [],
    prices: [],
    stripe_link: "",
  };
};

const store = new Vuex.Store({
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
});
//store.dispatch("fetchProducts");

fs.auth.onAuthStateChanged((user) => {
  if (user) {
    //console.log(user);
    store.dispatch("setUser");
    store.dispatch("isClient");
    store.dispatch("fetchProducts");
  } else {
    // User not logged in or has just logged out.
    //store.dispatch("unsetUser");
  }
});

export default store;
