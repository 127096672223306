
import firebase from 'firebase';
//import firebase from 'firebase/app';
//import * as firebase from 'firebase/app'

import 'firebase/auth';
import 'firebase/firestore';


var firebaseConfig = {
    apiKey: "AIzaSyDCnBISQ95Xex66qpUU1_ohiszTd0yGSXE",
    authDomain: "abstraktion-saas.firebaseapp.com",
    databaseURL: "https://abstraktion-saas.firebaseio.com",
    projectId: "abstraktion-saas",
    storageBucket: "abstraktion-saas.appspot.com",
    messagingSenderId: "860198814735",
    appId: "1:860198814735:web:3ad5431d9e90aad8bc4f7a",
    measurementId: "G-RER3NV9MFN"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
/*const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
*/
// export utils/refs
export {
  db,
  auth,
  usersCollection,
  //postsCollection,
  //commentsCollection,
  //likesCollection
}
