<template>
  <div class="model page">
    <section class="section" v-if="isUserAuth">
      <h1 class="title">home</h1>

      <h2>studio</h2>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      model_path_id_latest: null,
      option_drive: false,
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  mounted() {
    if (typeof process.env.VUE_APP_OPTION_DRIVE !== "undefined") {
      this.option_drive =
        process.env.VUE_APP_OPTION_DRIVE == "true" ? true : false;
    }

    try {
      this.model_path_id_latest = localStorage.getItem("model_path_id_latest");
    } catch {
      console.log("localStorage error");
    }
  },
};
</script>


<style lang="scss" scoped>
@import "../scss/main.scss";
</style>