import * as fs from "../firestore";
import router from "../router/index";

const actions = {
  /**
   *
   */
  setUser: (context) => {
    context.commit("USER_SET", fs.auth.currentUser);
  },
  /**
   *  stripe_metadata_module : fabric, visual ...
   * product include price list
   */
  fetchProducts({ commit }) {
    fs.db
      .collection("products")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          //console.log(doc.id, ' ***=> ', doc.data())

          //prod_JDw4KnqYsR055H :fabric
          //prod_JcfV1huRr9Od65 :visual

          //if (doc.id == "prod_JcfV1huRr9Od65") {

          let prices = [];

          const priceSnap = await doc.ref.collection("prices").get();

          priceSnap.docs.forEach((priceDoc) => {
            //console.log(priceDoc.id, " => ", priceDoc.data());

            prices.push({
              price_id: priceDoc.id,
              price: priceDoc.data(),
            });
          });

          commit("PUSH_PRODUCT", {
            product_id: doc.id,
            product: doc.data(),
            prices: prices,
          });
        });
      });
  },
  /**
   *
   */
  isClient: async (context) => {
    //console.log(context);
    const uid = context.getters.getUid;
    /**
     *
     * code here
     *
     * */

    //context.commit("IS_CLIENT", true);

    await fs.db

      .collection("customers")
      .doc(uid)
      .get()
      .then(async (doc) => {
        //console.log(doc, uid, doc.data());
        const data = doc.data();
        //customer_id = data.stripeId,
        //customer_stripe_link = data.stripeLink;
        context.commit("SET_STRIPE_LINK", data.stripeLink);
        //state.custommerStripeLink = customer_stripe_link; //

        /**
         * check if user has a subscription
         */
        await fs.db

          .doc("customers/" + uid)
          .collection("subscriptions")
          .get()
          .then(async (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              //console.log(doc.id, "=>", doc.data());

              const data = doc.data();

              //console.log(data.items);
              let items = null;
              items = data.items;
              if (items.length == 0) {
                // not a client
                context.commit("IS_CLIENT", false);
                return false;
              } else {
                for (let i = 0; i < items.length; i++) {
                  //console.log(items[i]);
                  // studio prod_Je4Yh2rgSScsdP fabric prod_JcfV1huRr9Od65
                  if (items[i].plan.product == "prod_Je4Yh2rgSScsdP") {
                    // state.subscription = data;
                    // state.subscription_role = data.role;
                    // state.subscription_status = data.status;

                    //console.log("product ok");
                    if (
                      data.canceled_at === null &&
                      data.ended_at === null &&
                      data.status == "active"
                    ) {
                      context.commit("IS_CLIENT", true);
                      //console.log("active", this.is_client);
                      //state.subscription_status = true;
                      return true;
                    } else {
                      //state.subscription_status = false;
                    }
                    context.commit("IS_CLIENT", true);
                    //console.log("***--***");
                    return true;
                  }
                }
              }

              /**
               * cancel_at
               * cancel_at_period_end
               * canceled_at
               * created.second
               * current_period_start.second
               * current_period_end.second
               * ended_at
               * items (array)
               * quantity
               * role (basic,bucket..)
               * status (active)
               * stripeLink (subscription dashboard)
               * trial_start
               * trial_end
               *
               * references :
               * price
               * prices (array of ref)
               * product
               */

              /*data.price.get().then(doc => {
                                        console.log(doc.data())
                                    })

                                    data.product.get().then(doc => {
                                        console.log(doc.data())
                                    })*/
              //this.state.client = data;
            });
          })
          .catch((/*error*/) => {
            //console.log(error)
          });

        //console.log('---+',clientSubscriptionRef)
      })
      .catch((/*error*/) => {
        //console.log(error)
      })
      .then((/*doc*/) => {
        //console.log('promise === ' , doc )
      });

    //console.log(client)

    return false;
    /**
     *
     * end
     *
     */
  },
  /**
   *
   * @param {*} param0
   */
  async logout({ commit }) {
    // log user out
    await fs.auth.signOut();

    // clear user data from state
    commit("USER_UNSET", {});

    // redirect to login view
    //if (this.$router.currentRoute.path !== "/")
    router.push("/");
  },
};

export default actions;
