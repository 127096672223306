<template>
  <div class="component headbar">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            class="headbar__logo"
            :src="require('../assets/abstraktion/abstraktion-fashion-logo.svg')"
          />
        </b-navbar-item>
      </template>
      <template #start v-if="isUserAuth">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          Home
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/studio' }">
          Studio
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/studio/new' }">
          Create
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/profile' }">
          Profile
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <router-link
              to="/login"
              v-if="!isUserAuth"
              class="button is-primary link"
            >
              <strong>Sign up</strong>
            </router-link>
            <router-link
              to="/login"
              v-if="!isUserAuth"
              class="button is-light link"
            >
              Log in
            </router-link>
            <a v-if="isUserAuth" @click="logout()" class="mx-4 is-light link">
              Log out
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
//import store from "./../store";
import { mapGetters } from "vuex";

export default {
  name: "HeadBar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth", "getModules"]),
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("logout", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.headbar {
  text-transform: uppercase;

  .navbar {
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
    .navbar-brand .navbar-item {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
  }

  &__logo {
    height: 100px; //5rem;
    max-height: unset;
  }
}
</style>